import React from "react";
import {  useNavigate } from "react-router-dom";
import "../Navbar.scss";
import $ from "jquery";


const Navigation = () => {

    const navigate = useNavigate();

    return (
    <>
    <div id="nav-container">
    <div class="toggle-icon">
    <span class="bar"></span>
    <span class="bar"></span>
    <span class="bar"></span>
    </div>  
    </div>
    <div className="navbar">
    <div className="navbar-name">
        Explore More
    </div>
    <div className="navbar-navs">
      <div onClick={()=> {
         $('.toggle-icon').click()
          navigate('/')
        }}      
       className="navbar-navs-nav">
        HOME 
      </div>
      <div onClick={()=> {
         $('.toggle-icon').click()
        navigate('/archieve')
      }} className="navbar-navs-nav">
        ARCHIVE
      </div>
      <div onClick={()=> {
         $('.toggle-icon').click()
        navigate('/contact')
      }}
      className="navbar-navs-nav">
        Contact
      </div>
    </div>
    </div>
    </>
    )

};

export default Navigation;