import React, {useEffect, useState, useRef} from "react";
import "../App.css";
import Header from "./header";
import IntroOverlay from "./Intro";
import HeaderPortFolio from "./headerportfolio";
import Details from "./Details";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Draggable } from 'gsap/Draggable';
import $ from "jquery";


const Home = ({portfolios}) => {
    let r = useRef(0)

    const [ind, setIndex] = useState(0);
    useEffect(()=> {
        $('.section-img').ripples({
          resolution: 202,
          dropRadius: 2,
          perturbance: 0.05,
        });
      },[])
    
      useEffect(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        //stops flashing of page
     
        gsap.to('body', 0, {css: {visibility: "visible"}})
        const tl = gsap.timeline();
        tl.from('.first .line ', 2.6, {
                y: 70,
                opacity: 0,
                ease: "power4.out",
                delay: 1,
                stagger: {
                    amount: 0.5
                }                               
            }
        )
        .to('.first .overlay-top', 1.6, {
            height: 0,
            delay: -1,
            ease: 'expo.inOut',
            stagger: {
                amount: 0.4
            }
        })
      //   .to('.overlay-top:nth-child(2)', 1.6, {
      //     height: 0,
      //     delay: 0,
      //     ease: 'expo.inOut',
      //     // stagger: {
      //     //     amount: 0.4
      //     // }
      // })
        .to('.first .intro-text',1, {
            delay: -1.6,
            css: {color: "white"}
        })
        // .to(".overlay-bottom",1.6,{
        //     width: 0,
        //     ease: 'expo.inOut',
        //     delay: -0.6,
        //     stagger: {
        //         amount: 0.6
        //     }
        // })
        .to('.first .intro-overlay', 0, {
            css: {display: 'none'}
        })
        .fromTo('.first .section-img', {
          scale: 1.2
        }, {
          scale: 1,
          duration: 1.6,
          ease: 'power3.out'
        },"-=1") 
    }, [])
    
    
    useEffect(()=> {
      let ele = document.getElementById("portfolio-image");
      let pos = ele.getBoundingClientRect();
      Draggable.create('#portfolio-image',{
       bounds: $('.second')[0],
       type: 'x,y',
       dragClickables: true,
       dragResistance: 0.25,
       onDragStart:function() {
        $('#stalker').css({display: 'none'});
        // console.log("x: "+pos.left+" y: "+pos.top);
       },
       onDragEnd: function() { 
    
        $('#stalker').css({display: 'flex'})
        let prevPosX = this.x;
        let prevPosY = this.y;
        let posX = this.x + pos.left 
        let posY = this.y + pos.top
        let detailTL = gsap.timeline();
        // console.log(prevPosX+ ":" + prevPosY);
        // console.log("x: "+pos.left+" y: "+pos.top);
    
        detailTL.to('.project-details',1.6,{
          opacity: 1,
          zIndex: 199
        })
        .to('body', 0, {
          overflow: 'hidden'
        })
        .to('.portfolio-div .line,.line-2', 0,{
          opacity: 0,
          delay: -2
        } )
        .to('#portfolio-image', 0, {
          opacity: 0,
          delay: -2,
        })
        .to('#stalker', 0, {
          opacity: 0,
          delay: -2,
        })
        .fromTo('.col-md-6:nth-child(1)', 1.6, {
          x: posX,
          y: posY,
          scale: 0.3
        }, {
          x: 0,
          y: 0,
          scale: 1,
          delay: -2
        })
        .fromTo('.col-md-6:nth-child(2)', 1.6, {
          opacity: 0,
          scale: 0.7
        }, {
          opacity: 1,
          scale: 1,
          delay: -2
        })
        .to('.portfolio-div .line,.line-2', 0,{
          opacity: 1
        } )
        .to('#portfolio-image',0,{
          x: 0,
          y: 0,
          opacity: 0
        })
        .to('#stalker', 0, {
          opacity: 1,
          delay: -2,
        })
    
       }
      })
    
    },[]);
    
    useEffect(()=> {
      $('.project-close').on('click',()=> {
        let closeTL = gsap.timeline();
        let ele = document.getElementById("portfolio-image");
        let pos = ele.getBoundingClientRect();
        console.log("x: "+pos.left+" y: "+pos.top);
        closeTL.to('.project-details',1, {
          scale: 0.4,
          opacity: 0   
        })
        .fromTo('#portfolio-image',{
          opacity: 0,
          y: 70,
          scale: 4,
          duration: 0,
        }, {
          scale: 1,
          y: 0,
          opacity: 1,
          duration: 1,
          ease: 'power4.out'
        },"-=1")
        .to('.project-details', 0, {
          zIndex: -2,
          scale: 1
        })
        .to('body',0,{
          overflow: 'auto'
        })
      })
      
    
    },[])
    

    useEffect(()=> {
        $('.second, .project-next').on('click', function(){
          let trigger = portfolios.length-1;
          console.log("trigger "+trigger);
          if(r.current == trigger){
          console.log("CLcikeddddd "+ ind + " : "+ portfolios.length); 
            r.current = 0;     
            setIndex(0);
          }else{
          console.log("Cccccccc "+ ind + " : "+ portfolios.length);
            r.current += 1;
            setIndex(ind+1);
          }
      
          let changeTL = gsap.timeline();
      
          changeTL.fromTo('#portfolio-image-img, .project-details-image',1,{
            opacity: 1,
            scale: 0.6,
            transform: 'rotate(-15deg)'
          },{
            opacity: 1,
            scale: 1,
            transform: 'rotate(0deg)'
          }).fromTo('.portfolio .line .intro-text, .project-details-info-div div ', {
            opacity: 0
          },{
            opacity: 1,
            stagger: {
              amount: 0.5
            }
          },"-=1")
          .fromTo(".portfolio .skills div[class^='skills-div'], div[class*=' skills-div']", {
            opacity: 0,
            x: -200
          },{
            opacity: 1,
            x: 0,
            stagger: {
              amount: 0.5
            }
          },"-=1")
          
          
        })
      },[])
    
    
    useEffect(()=> {
      let sections = gsap.utils.toArray("section");
      let currentSection = sections[0];
      let innerHeight = window.innerHeight;
    
      gsap.defaults({overwrite: 'auto', duration: 0.3});
    
      // stretch out the body height according to however many sections there are.
      gsap.set(".App", {height: (sections.length * 100) + "vh"});
    
      const setSection = (newSection) => {
        
        if (newSection !== currentSection) {
            
          let tl = gsap.timeline();
    
          if(newSection.classList[0] != "first"){
            tl.to('.first .line', 1.2, {
              y: 70,
              opacity: 0,
              ease: "power4.out",
              stagger: {
                  amount: 0.2
              }            
            })
            .to('.section-img',2, {
              delay: -1,
              scale: 1.2,
              zIndex: 4
            })
            .to('.first', 3, {
               filter: 'blur(10px)', delay: -1, ease: 'easeOut', autoAlpha: 0
            })
            .to('.second', 1, {
              opacity: 1,
              scale: 1, filter:'blur(0px)', delay: -4,  autoAlpha: 1
            })
            .fromTo('.second .line', 1, {          
              y: 70,
              opacity: 0,
              autoAlpha: 0,            
            }, {
              y: 0,
              opacity: 1,
              ease: "power4.out",
              autoAlpha: 1,
              stagger: {
                  amount: 0.5
              } 
            }, "-=3.5")
            // .to('.second .line', 2, {          
            //   delay: -5,
            //   ease: "power4.out",
            //   opacity: 1,
            //   y: 0,
            //   autoAlpha: 1,
            //   stagger: {
            //       amount: 0.5
            //   }   
            // })
    
            // gsap.to(newSection, {scale: 1, filter:'blur(0px)', duration: 2, delay: 0.5,  autoAlpha: 1})
            
          }else{
            tl.to(".second .line", 1, {
              y: 70,
              opacity: 0,
              ease: "power4.out",
              stagger: {
                  amount: 0.3
              }            
            }).to('.second', 1, {
              scale: 0.8,  delay: -1, filter: 'blur(10px)', autoAlpha: 0
            })
            .to('.first', 2, {
              filter: 'blur(0px)', delay: -1.5, ease: 'easeOut', autoAlpha: 1
            })
            .to('.section-img', 2,{
              delay: -2,
              scale: 1,
              zIndex: -2
            }).fromTo('.first .line', 1.5, {          
              y: 70,
              opacity: 0,
              ease: "power4.out",
              autoAlpha: 0,
                
            }, {
              y: 0,
              opacity: 1,
              ease: "power4.out",
              autoAlpha: 1,
              stagger: {
                  amount: 1
              } 
            }, "-=1.5")
            
            // gsap.to(currentSection, {scale: 0.8, duration: 1, filter: 'blur(10px)', autoAlpha: 0})
            // gsap.to(newSection, {scale: 1, filter:'blur(0px)', duration: 2, delay: 0.5,  autoAlpha: 1});
    
          }
        // gsap.to(`section:nth-child(2) .intro-text`, 1, {autoAlpha: 0})
        
        currentSection = newSection;
        
      }
      }
      // create a ScrollTrigger for each section
      sections.forEach((section, i) => {
      ScrollTrigger.create({
      // use dynamic scroll positions based on the window height (offset by half to make it feel natural)
      start: () => (i - 0.5) * innerHeight,
      end: () => (i + 0.5) * innerHeight,
      // when a new section activates (from either direction), set the section accordinglyl.
      onToggle: self => self.isActive && setSection(section)
      });
      });
    
      // $(".intro-country").on('mouseover', ()=> {
      //   let previewTL = gsap.timeline();
      //   console.log("Called timeline");
      //   previewTL.to('.preview-image', 1.6, {
      //     zIndex: -1,
      //     opacity: 1,
      //     scale: 1
      //   })
      // })
    
      // $(".intro-country").on('mouseout', ()=> {
      //   let previewTL = gsap.timeline();
      //   console.log("Revoke timeline");
      //   previewTL.to('.preview-image', 0.6, {
      //     zIndex: -4,
      //     opacity: 0,
      //     scale: 0.7
      //   })
      // })
    
      $(".intro-name").on('mousemove', (e)=> {
        let previewTL = gsap.timeline();
    
        previewTL.to('.preview-image-3', {
          left: e.clientX,
          top: e.clientY,
          filter: 'blur(0px)',
          transform: "translateX(-50%) translateY(-50%)",
          opacity: 0.7,
          ease: "power4.out"
        })
        // previewTL.to('.preview-image-2', 1.6, {
        //   zIndex: -1,
        //   opacity: 1,
        //   scale: 1
        // })
      })
    
      $(".intro-name").on('mouseout', ()=> {
        let previewTL = gsap.timeline();
        previewTL.to('.preview-image-3', 0.6, {
          opacity: 0
        })
      })
    
    },[])
    
    useEffect(()=> {
      $('.second').on('mousemove',(e)=> {
        let x = e.clientX;
        let y = e.clientY;
        let transformValue = `translate3d(${x}px, ${y}px, 0) translateX(-50%) translateY(-50%)`
        let d = x * 180/ window.innerWidth
        $('#stalker').css({transform: transformValue})
        $('#stalker-arrow').css({transform: `rotate(${d}deg)`, transition: '0.2s'})
    
        // let moveTL = gsap.timeline({delay: 2});
    
        // moveTL.to()
      })
    
      $('.second #portfolio-image').on('mouseover', (e) => {
        $('#stalker').css({width: '10vw', height: '10vw', border: 'white 3px dotted'})
        $('#stalker-arrow, .stalker-text').css({color: 'white'})    
        $('#portfolio-image').css({transform: 'rotate(5deg)', cursor: 'grabbing'})
        $('.stalker-text')[0].innerHTML="Drag"
      })
      $('.second  #portfolio-image').on('mouseout', (e) => {
        $('#stalker').css({width: '25rem', height: '25rem', border: 'black 3px dotted' });
        $('#stalker-arrow, .stalker-text').css({color: 'black'}) 
        $('#portfolio-image').css({transform: 'rotate(0deg)', cursor: 'pointer'});
        $('.stalker-text')[0].innerHTML="Click"
      })
    
      $('.second #portfolio-image').on('mouseover', (e) => {
        $('#stalker').css({width: '10vw', height: '10vw', border: 'white 3px dotted'})
        $('#stalker-arrow, .stalker-text').css({color: 'white'})    
        $('#portfolio-image').css({transform: 'rotate(5deg)', cursor: 'grabbing'})
        $('.stalker-text')[0].innerHTML="Drag"
      })
    
      $('.second #portfolio-image').on('mouseover', (e) => {
        $('#stalker').css({width: '10vw', height: '10vw', border: 'white 3px dotted'})
        $('#stalker-arrow, .stalker-text').css({color: 'white'})    
        $('#portfolio-image').css({transform: 'rotate(5deg)', cursor: 'grabbing'})
        $('.stalker-text')[0].innerHTML="Drag"
      })
    
      $("div[class^='skills-div'], div[class*=' skills-div']").on('mouseover', (e) => {
        let hoverSkill = gsap.timeline();
        let clsName =  e.target.className
    
        hoverSkill.to(`.portfolio .${clsName} `, 0,{
            color: 'white'
        })
        .to(`.portfolio .${clsName} .skill-div-circle`, 0.4, {
            opacity: 1,
            scale: 5
        })
      })
    
      $("div[class^='skills-div'], div[class*=' skills-div']").on('mouseout', (e) => {
        let hoverSkill = gsap.timeline();
        let clsName =  e.target.className
        hoverSkill
        .to(`.portfolio .${clsName} .skill-div-circle`, 0.4, {
            scale: 1,
            opacity: 0
        })
        .to(`.portfolio .${clsName} `, 0,{
          color: '#FF513B',
        })
      })
    },[])

    useEffect(()=> {
    

      var element = $(".arc-scroll");

      var tl = gsap.timeline();

      tl.fromTo(element,  {
        y:"+=20"
      }, {
        y:"-=20",
        yoyo:true,
        duration: 1,
      repeat: Infinity,
        ease: 'easeIn'
      })
      .repeatDelay(2)
      .repeat(-1);

  },[])

    return (<div className="App">
    <section className="first">  
    <IntroOverlay></IntroOverlay>
      <div className="section-img">
      </div>
      <div className="preview-image">
      </div>  
      <div className="preview-image-2">
      </div> 
      <div className="preview-image-3">

      </div>
      <Header/>
      <div className="arc-scroll">Scroll</div>
    </section>
    <section  className='second'>     
      <HeaderPortFolio portfolio={portfolios[ind]} />
      <div id="stalker">
        <i id="stalker-arrow" class="fas fa-long-arrow-alt-left"></i>
        <div  className="stalker-text">Click</div>
      </div>
    </section>
    <Details portfolio={portfolios[ind]} />
    </div>)
};

export default Home;