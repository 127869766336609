import React from "react";
import "./Social.scss"
import checkPdf from "../images/maheshshinde.pdf"

const Social = () => {
{/* <i class="fab fa-github-square"></i> */}
    return (
        <>
        <a rel="noreferrer" href={checkPdf} download><i class="fas fa-file"></i></a>
        <a rel="noreferrer" href="https://github.com/masShinde" target="_blank" ><i class="fab fa-github-square"></i></a>
        <a rel="noreferrer" href="https://www.linkedin.com/in/mahesh-shinde-dev/" target="_blank" ><i class="fab fa-linkedin-in"></i></a>
        <a rel="noreferrer" href="mailto:maheshshinde787@gmail.com" target="_blank"><i  class="fas fa-envelope"></i></a>
        <div className="Social">
            <div className="social-icon">
            </div>
            <div className="social-facebook">
           
            </div>
      
            <div className="social-instagram">

            </div>
            <div className="social-linkedin">

            </div>
            <div className="social-overlay">

            </div>
        </div>
        </>
    )

}

export default Social;