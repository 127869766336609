import React from "react";
import "./header.scss";

const Header = () => {
  
    return (
        <div className="intro">
            <div className="intro-div">
                {/* <div className="line">
                <span className="intro-text">Hi </span> <span className="intro-text">I </span><span className="intro-text">am </span>
                </div> */}
                <div className="line">
                <span className="intro-text">Hi</span>
                </div>
                <div  className="line line-2">
                <span className="intro-text">My Name is </span><span className="intro-text intro-name">Mahesh</span> 
                </div>
                <div  className="line line-3">
                <span className="intro-text">Full Stack Developer</span>
                </div>
                <div  className="line line-4">
                <span className="intro-text">from </span><span className="intro-text intro-country">INDIA</span>
                </div>
                {/* <div className="line">
                <span className="intro-text">Shinde</span>
                </div> */}
            </div>
        </div>
    )
}
export default Header;