import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Draggable } from 'gsap/Draggable';
import "./App.css";
import $ from "jquery";
import "jquery.ripples";
import Archieves from './Components/Archieves';
import plasito from "./images/plasitoo.png"
import sinhgadK from "./images/sinhgadK.png"
import "./Navbar.scss"
import Home from './Components/Home';
import Navigation from './Components/Navigation';
import Me from './Components/Me';
import fashion from "./images/fashion.png"
import fruits from "./images/fruits.png"
import block from "./images/blockchain.jpg";
import food from "./images/food.jpg"
import native from "./images/native-features.png"


function App({history}) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(Draggable);

  const [ portfolios, setPortfolios ] = useState([
    {
        description:  ` Plasito is an Ecommerce Web Application with Customer and Admin Panel. It has features like Authentication, Cart, Product Details and comments,
        Admin Panel Dashboard, FAQ, Contact Us, Payment Gateway and etc. A graphical Dashboard helps Admin to track Users, Orders, User Queries,  etc.`,
        image: plasito,
        header: "Plasito.com",
        skills: ["React","Node","Express","MongoDB","BootStrap"]
    },
    {
      description:  `Singhgad Karandak is a College Fest Management Site. It has a landing page and registration Portal. It generates a unique one-time QR Code for each registered user.
      Admin can verify this QR code to validate the Authenticity of User. Once verified the QR code gets disposed and can not be used further.`,
      image: sinhgadK,
      header: "SinhgadKarandak",
      skills: ["HTML","CSS","PHP","SQL","Javascript"]
  }
]);


useEffect(()=> {
  $(".toggle-icon").on('click',function() {
    $('#nav-container').toggleClass("pushed");
    if($('#nav-container')[0].classList[0] == 'pushed'){
      let navTl = gsap.timeline();

      navTl.to('.navbar', 0.8,{
      top: 0,
      opacity: 1
      })
    }else{
      let navTl = gsap.timeline();

      navTl.to('.navbar', 0.8, {
      top: '-200vh',
      opacity: 0
      })
    }
    
  });

  $('.navbar-navs-nav').on('mouseover', function(e) {
    let childIndex = $(this).index() + 1;
    if(window.innerWidth > 700){
    $(`.navbar-navs-nav:not(:nth-child(${childIndex}))`)
    .css({
      flexGrow: 0.4
    })
    $(`.navbar-navs-nav:nth-child(${childIndex})`)
    .css({
      flexGrow: 1
    })}
  })

  $('.navbar-name').on('mouseover', ()=> {
    $('.navbar-navs-nav').css({
        flexGrow : 1
    })
  })


},[])



  return (
    <>
      

    <Router>
    <Navigation />
    <Routes>   
    <Route path="/archieve" element={<Archieves />} exact />
    <Route path="/contact" element={<Me />}  exact />
    <Route path="/" element={<Home portfolios={portfolios}  />} exact />
    </Routes>
    </Router>
    </>
  );
}

export default App;
