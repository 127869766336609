import React, {useState} from "react";
import "./Details.scss";

const Details = ({portfolio}) => {
    

    return (
        
        <div className="row project-details">
            <div className="col-md-6">
                <div  className="project-close">
                 <i style={{fontSize: '3rem'}} class="fas fa-times"></i></div>
                <div className='project-details-image-div'>
                    <img key={portfolio.header} className="project-details-image" alt="backimg" src={portfolio.image} />
                </div>
            </div>
            <div className="col-md-6 ">
                <div className="project-details-info-div">
                <div>{portfolio.header}</div>
                 <div>{portfolio.description}</div>
                </div>
                <i class="fas fa-chevron-right project-next"></i>
            </div>
        </div>        
    )
}

export default Details;