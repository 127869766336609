import React from "react";
import "./headerPortfolio.scss";


const HeaderPortFolio = ({portfolio}) => {

    return (
        <div className="portfolio">
            <div className="portfolio-div">
                {/* <div className="line">
                <span className="intro-text">Hi </span> <span className="intro-text">I </span><span className="intro-text">am </span>
                </div> */}
                <div  className="line">
               {"portfolio".split('').map((c)=> <span key={c} className="intro-text">{c}</span>)}
                </div>
                
                <div  className="line-2">
                <div className="intro-text">{portfolio.header}</div>
                </div>
              
                <div                
                    id="portfolio-image">
                    <img alt="portfolio-img" id="portfolio-image-img" src={portfolio.image} />
                </div>
                <div className="skills">
                    {portfolio.skills.map((s, i) =><div key={i} className={`skills-div-${i}`}>{s}<div className="skill-div-circle"></div></div>) }
                </div>
            </div>
        </div>
    )
}
export default HeaderPortFolio;