import React, {useEffect, useState, useCallback} from "react";
import $ from "jquery";
import "./Archieves.scss";
import { gsap, Expo } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import fashion from "../images/fashion.png"
import fruits from "../images/fruits.png"
import block from "../images/blockchain.jpg";
import food from "../images/food.jpg"
import native from "../images/native-features.png"


console.log(fruits);
const Archieves = () => {

// const [y, setY] = useState(window.scrollY);
// const [sec, setSec] = useState(0);
// const[words, setWords] = useState(['First, Second, Third']);

// const handleNavigation = useCallback(
//   e => {
   
//   }, [y]
// );

// useEffect(() => {
//   setY(window.scrollY);
//   window.addEventListener("scroll", handleNavigation);

//   return () => {
//     window.removeEventListener("scroll", handleNavigation);
//   };
// }, [handleNavigation]);

const [arcArray, setArcArray] = useState(['BlockChain', "React Native","React Native","React Native","React Native"]);
const [arcDescription, setArcDescription] = useState([
    ['A Blockchain project, copy of BITCOIN.',' With Features like Proof of Work, Distributed Consensus','Mining and Transaction Tracing'],
    ['A fashion App, where user can buy clothes according to gender, age.',' Message Authentication is added using Firebase.'],
    ['A Mobile Ecommerce App for Fruits. The main features are',' Authentication, Customer Panel, Admin Panel, Cart and etc.'],
    ['A Hotel Finder App, Where User can find', 'nearby hotels according to the location', 'Features like search by location.'],
    ['A plane React-native project with native features like Camera, GPS and etc.','It is a Place Saver application where user can save the image,','GPS location and information of a place.']
])
const [check, setCheck] = useState(0);

    

    useEffect(()=> {
        $('body').css({visibility: 'visible'});

        var element = $(".arc-scroll");

        var tl = gsap.timeline();

        tl.fromTo(element,  {
          y:"+=20"
        }, {
          y:"-=20",
          yoyo:true,
          duration: 1,
        repeat: Infinity,
          ease: 'easeIn'
        })
        .repeatDelay(2)
        .repeat(-1);

    },[])

    useEffect(()=> {

        let initialTL = gsap.timeline();

        initialTL.from('.arc-line',{
            opacity: 0,
            y: 70,
            duration: 1.3,
            stagger: {
                amount: 0.5
            }
        })
    },[])

    const textAnimation = () => {
        let tl = gsap.timeline();
        $('.sec-description-div-title')
        .css({ opacity: 1 })
        tl.fromTo('.sec-desc-span-title', 1, {
            opacity: 0,
            display: 'none',
            fontSize: '6rem'
        },{
            opacity: 1,
            display: 'inline',
            fontSize: '12rem',
            stagger: {
                amount: 0.5
            }
        })
        .fromTo('.sec-description-div-desc div',1, {
            opacity: 0,
            y: 80,
            fontSize: '2rem'
        },{
            opacity:1,
            y: 0,
            delay: -1,
            fontSize: '4rem',
            stagger: {
                amount: 0.5
            }
        })
    }

    const textAnim = () => {
        let tl = gsap.timeline();

        tl.to('.sec-description-div-desc div',1 ,{
            opacity: 0,
            y: -80,
            stagger: {
                amount: 0.5
            }
        })

    }

   useEffect(()=> {
        gsap.set(".sec-project-preview",{width: 0});



        // if(window.innerWidth <= 1024){
        // $('.sec-navigation-item').on('click',
        // (e)=> {
        //     let tl = gsap.timeline();
        //     // gsap.set(".sec-project-preview",{backgroundImage: 'url(/static/media/indian.09c19bfa.jpg)'});
        //     tl.to('.sec-project-preview', 1, {
        //         width: window.innerWidth < 900 ? '100vw' : '600px',
        //         ease: Expo.easeInOut,
        //         left: window.innerWidth < 900 ? 0 : e.clientX ,
        //         top: e.clientY,
        //         transform: 'translateY(-50%)'
        //     })
        // });
        // }else{

            $('.sec-navigation-item').on('mouseover',
            (e)=> {
                console.log("ITemss");
                let tl = gsap.timeline();
                tl.to('.sec-project-preview', 0.6, {
                    width: window.innerWidth < 900 ? '100vw' : '600px',
                    ease: Expo.easeInOut,
                    left: window.innerWidth < 900 ? 0 : e.clientX ,
                    top: e.clientY,
                    transform: 'translateY(-50%)'
                })
            });
        // }
    


        $(window).on('scroll',
        (e)=> {
            let tl = gsap.timeline();
            tl.to('.sec-project-preview', 0.5, {
                width: 0,
                ease: Expo.easeInOut
            });
            $('.sec-description-div-title')
            .css({ opacity: 0 })
            textAnim()
        })


        // $('.archieve-second').on('mouseover',
        // (e)=> {
        //     let tl = gsap.timeline();
        //     tl.to('.sec-project-preview', 0.5, {
        //         width: 0,
        //         ease: Expo.easeInOut
        //     })
        //     textAnim();
        // })

      

        $('.sec-navigation-link-4').on('mouseover', ()=> {
            $('.sec-project-preview')
            .css({ "background-image" : 'url(/static/media/food.5e3567ba.jpg)' })
            $('.sec-description-div-title')
            .css({ "left" : '8rem' })
            setCheck(3);
            textAnimation()
        })

        $('.sec-navigation-link-1').on('mouseover', ()=> {
            $('.sec-project-preview')
            .css({ "background-image" : 'url(/static/media/blockchain.d8eb34ae.jpg)' })
            $('.sec-description-div-title')
            .css({ "left" : '24rem' })
            setCheck(0);
            textAnimation()
        })

        $('.sec-navigation-link-2').on('mouseover', ()=> {
            $('.sec-project-preview')
            .css({ "background-image" : 'url(/static/media/fashion.b4588cec.png)' })            
            $('.sec-description-div-title')
            .css({ "left" : '24rem' })
            setCheck(1);
             textAnimation()

        })
        $('.sec-navigation-link-5').on('mouseover', ()=> {
            $('.sec-project-preview')
            .css({ "background-image" : 'url(/static/media/native-features.33d34a90.png)'})
            
            $('.sec-description-div-title')
            .css({ "left" : '24rem' })
            setCheck(4);
             textAnimation()

        })

        $('.sec-navigation-link-3').on('mouseover', ()=> {
            console.log("Over");
            $('.sec-project-preview')
            .css({ "background-image" : 'url(/static/media/fruits.8c5801ab.png)' })
            $('.sec-description-div-title')
            .css({ "left" : '8rem' })
            setCheck(2);
            textAnimation()
        })
        
   },[]);

   useEffect(()=> {

    $('.sec-navigation-item').on('mouseout',
    (e)=> {
        let tl = gsap.timeline();
        tl.to('.sec-project-preview', 0.5, {
            width: 0,
            ease: Expo.easeInOut
        });
        $('.sec-description-div-title')
        .css({ opacity: 0 })
        textAnim()
    })
   },[]);

   useEffect(()=> {

    // $(window).on('scroll', ()=> {
    //     let scroll = $(window).scrollTop();
    //     let dh = $(document).height();
    //     let wh = $(window).height();
    //     let scrollPercent = (scroll / (dh - wh)) * 100;
    //     $(".sec-progress-bar").css({
    //         'height': `${scrollPercent}%`
    //     })
    // })

    let lis = gsap.utils.toArray('.sec-navigation-item');

    const tCh = (s,i) => {
        let tl = gsap.timeline();
        let n  = i+1;
        tl.to(`.navigation-overlay-${i}`, 1, {
            width: 0,
            transformOrigin: 'right'
        })
       .to(`.navigation-overlays-${i}`, 1, {
           width: 0,
           delay: -.6,
           transformOrigin: 'right'
       })
       .to(`.navigation-v-overlays-${i}`, 1, {
        top: '10%',
        bottom: '10%',
        height: '80%',
        transformOrigin: 'bottom'
    })
    .to(`.navigation-v-overlays-${i}`, 1, {
        top: '10%',
        bottom: '10%',
        height: '0%',
        transformOrigin: 'bottom'
    })
      
    }


    lis.forEach((section, i) => {
        ScrollTrigger.create({
        // use dynamic scroll positions based on the window height (offset by half to make it feel natural)
        trigger: section,
        toggleActions: 'play none none none',
        start:  "top 95%",
        // when a new section activates (from either direction), set the section accordinglyl.
        onToggle: self => self.isActive && tCh(section, i),
        });
        });
   },[])

   useEffect(()=> {
    const canvas = document.getElementById("canvas1");
    const ctx = canvas.getContext("2d");
    ctx.canvas.width  = window.innerWidth;
    ctx.canvas.height = window.innerHeight;
    
    let particleArray = [];
    
    // GET MOUSE POSITION ///////////////////////////////
    const mouse = {
        x: null,
        y: null
    }
    $('.archieve-intro')[0].addEventListener('mousemove', function(event){
            mouse.x = event.x;
            mouse.y = event.y;
    });
    // SET MOUSE POSITION AS UNDEFINED EVERY 5 SEC(to prevent effect getting stuck in corners when mouse leaves window)//////
    setInterval(function(){
        mouse.x = undefined;
        mouse.y = undefined;
    }, 200);
    
    // CREATE PARTICLE OBJECT ///////////////////
    class Particle {
        constructor(x, y, size, color, weight){
            this.x = x;
            this.y = y;
            this.size = size;
            this.minSize = size;
            this.color = color;
            this.weight = weight;
        }
        draw(){
            ctx.beginPath();
            ctx.arc(this.x,this.y,this.size,0,Math.PI * 2, false);
            ctx.fillStyle = this.color;
            ctx.fill();
        }
        update(){
            this.size-=0.05;
            if (this.size < 0) {
                this.x = (mouse.x + ((Math.random() * 20) - 10));
                this.y = (mouse.y + ((Math.random() * 20) - 10));
                this.size = (Math.random()*15) + 2;
                this.weight = (Math.random() * 2) - 0.5;
            }
            this.y += this.weight;
            this.weight += 0.05;
                                
            // if it reaches bottom bounce
            if (this.y > canvas.height-this.size){
                    this.weight *= -.5;
            };
        }
    }
    
    function init() {
        particleArray = [];
        for (let i = 0; i < 150; i++){
            let size = (Math.random() * 10) + 1;
            let x = Math.random() * (window.innerWidth - size * 2) + size;
            let y = canvas.height - 100;
            let color = 'pink';
            let weight = 1;
            particleArray.push(new Particle(x, y, size, color, weight));
        }
    
    }
    
    function animate(){
        ctx.fillStyle = 'rgba(0,0,0,.04)';
        //ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        for (let i = 0; i < particleArray.length; i++) {
            particleArray[i].update();
            //particleArray[i].draw();
        }
        connect();
        requestAnimationFrame(animate);
    }
    init();
    animate();
    
    // check if particles are close enough to draw line between them
    function connect() {
        let opacityValue = 1;
        for (let a = 0; a < particleArray.length; a++) {
            for (let b = a; b < particleArray.length; b++){
                let distance = ((particleArray[a].x - particleArray[b].x) * (particleArray[a].x - particleArray[b].x))
                +   ((particleArray[a].y - particleArray[b].y) * (particleArray[a].y - particleArray[b].y));
                if  (distance < (canvas.width/7) * (canvas.height/7))
                {   
                    opacityValue = 1-(distance/5000);
                    ctx.strokeStyle='rgba(255,255,255,' + opacityValue +')';
    
                    /*
                    let activeColor;
                    if ( particleArray[a].x < canvas.width/2) {
                        activeColor = 'rgba(255,255,255,' + opacityValue +')';
                    }
                    else {
                         activeColor = 'rgba(54,50,55,' + opacityValue +')';;
                    }
                    */
                    //ctx.strokeStyle= 'rgba(140,85,35,1)';
                    //ctx.strokeStyle= 'rgba(140,85,35,'+opacityValue+')';
                    //ctx.lineCap = 'round'; // for wide lines
                    //ctx.setLineDash([10]);
                    ctx.beginPath();
                    ctx.lineWidth = 1;
                    ctx.moveTo(particleArray[a].x, particleArray[a].y);
                    ctx.lineTo(particleArray[b].x, particleArray[b].y);
                    ctx.stroke();
    
                }    
        }
        }
        //ctx.restore();
      window.addEventListener('resize', function(e){
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
    });
    }
   },[])

    return(
        <>
        <div className="Archieve">
           <div className="archieve-intro">
                <canvas id="canvas1"></canvas>
                <svg  version="1.1">
                    <defs>
                    <filter id="goo">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                        <feComposite in="SourceGraphic" in2="goo" operator="atop"/>
                    </filter>
                    </defs>
                </svg>
                <div className="arc-text-left">Archieves</div>
                <div className="arc-scroll">Scroll</div>
                <div className="arc-text-right">Archieves</div>

                <div className="arc-line">
                {/* <h1 className="arc-text">
                    This is collection of Archieved projects
                </h1>
                </div>
                <div className="arc-line">
                <h1 className="arc-text">
                    You can Check my Github Repository 
                </h1>
                </div> */}
                <div className="arc-line">
                <h1 className="arc-text">
                    for More.
                </h1>
                </div>
           </div>
           <div className="archieve-second">
                <div className="arc-sec-left"></div>
                <div className="arc-sec-right"></div>
             
               <div className="sec-navigation-wrapper">
                    <div className="sec-project-preview-wrapper">
                        <div className="sec-project-preview"></div>
                    </div>
                    <ul className="sec-navigation-list">
                        <li className="sec-navigation-item ">
                            <a className="sec-navigation-link sec-navigation-link-1">
                                <span data-text="MasCoin">MasCoin</span>
                            </a>
                            <div className="navigation-overlay-0"></div>
                            <div className="navigation-overlays-0"></div>
                            <div className="navigation-v-overlays-0"></div>
                        </li>
                        <li className="sec-navigation-item">
                            <a href="https://expo.dev/@mas88/projext" target="_blank" rel="noreferrer" className="sec-navigation-link sec-navigation-link-2">
                                <span data-text="FashionHub">FashionHub</span>
                            </a>
                            <div className="navigation-overlay-1"></div>
                            <div className="navigation-overlays-1"></div>
                            <div className="navigation-v-overlays-1"></div>
                        </li>
                        <li className="sec-navigation-item">
                            <a href="https://expo.dev/@mas88/shop-dukaan" target="_blank" rel="noreferrer" className="sec-navigation-link sec-navigation-link-3">
                                <span data-text="FruitSHOP">FruitSHOP</span>
                            </a>
                            <div className="navigation-overlay-2"></div>
                            <div className="navigation-overlays-2"></div>
                            <div className="navigation-v-overlays-2"></div>
                        </li>
                        <li className="sec-navigation-item ">
                            <a href="https://expo.dev/@mas88/food" target="_blank" rel="noreferrer" className="sec-navigation-link sec-navigation-link-4">
                                <span data-text="HotelFinder">HotelFinder</span>
                            </a>
                            <div className="navigation-overlay-3"></div>
                            <div className="navigation-overlays-3"></div>
                            <div className="navigation-v-overlays-3"></div>
                        </li>    

                        <li className="sec-navigation-item ">
                            <a href="https://expo.dev/@mas88/Native-Features" target="_blank" rel="noreferrer" className="sec-navigation-link sec-navigation-link-5">
                                <span data-text="NativeFeatures">NativeFeatures</span>
                            </a>
                            <div className="navigation-overlay-4"></div>
                            <div className="navigation-overlays-4"></div>
                            <div className="navigation-v-overlays-4"></div>
                        </li>                  

                    </ul>
               </div>
                {/* <div className="sec-progress-bar"></div> */}
    
                    {/* <div className="sec-description-div-desc">
                    this is small info
                    </div> */}
           </div>

        </div>

        <div className="sec-description-div-title">
        {arcArray[check].split('').map(c => <span className="sec-desc-span-title">{c}</span>)}
        </div>

        <div className="sec-description-div-desc">
          {(arcDescription && arcDescription[check]) && arcDescription[check].map(line => line ? <div>{line}</div> : null)}
        </div>
        </div>
        </>
    )

};

export default Archieves;