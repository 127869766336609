import React, {useEffect} from "react";
import "./Me.scss";
import $ from "jquery";
import Social from "./Social";
import { gsap } from "gsap";


const Me = () => {

    useEffect(()=> {
        $('body').css({visibility: 'visible'});
    },[])

    useEffect(()=> {

        let tl = gsap.timeline();

        tl
        // .to('.me-back-overlay')
        
        .to('.me-header-text',0,{
            opacity: 1,
            delay: 0.4
        })        
        .fromTo('.me-header-div',1.2,{
            x: 100,
            opacity: 1
        }, {
            opacity: 1,
            x: 0,
            ease: 'easeIn'
        })
        .fromTo('.me-info-div', 1, {
            opacity: 0,
            y: 70
        },{
            opacity: 1,
            y: 0,
            ease: 'easeIn'
        },"-=1")
        .to('.me-section-head',1 ,{
            width: '30rem',
            delay: -0.5
        })
        .to('.Social',0.5,{
            // delay: 1,
            filter: 'blur(0px) contrast(0)',
            backgroundColor: 'transparent'
        })


    },[])

    return (
        <div className="Me">
        <Social />
        <div className="me-section-head">Contact Me</div>
        <div className="me-header-div">
            
            <div className="me-header-text">Mahesh</div>
            <div className="me-header-text">Shinde</div>
        </div>
        <div className="me-info-div">
            <div>I Work for<span> Airtel Digital</span>,<span>India</span></div><div>& Always</div>
            <div><span>looking</span> for Something <span>Fun</span>.</div>
            <div>Let's Do it <span>Together</span>.</div>
        </div>        
        <div className="me-image-div">

        </div>
        </div>
    )


};

export default Me;